import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "./context/GlobalContextProvider";
import "./App.scss";

import NavBar from "./components/js/NavBar";
import NavBarLarge from "./components/js/NavBarLarge";
import Films from "./pages/js/Films";
import Other from "./pages/js/Other";
import About from "./pages/js/About";

const env = process.env.REACT_APP_TEST_ENVIRONMENT_VARIABLE;

function App() {
  return (
    <div className="App">
      <Provider>
        <Router>
          <Route component={NavBarLarge} path="/" />
          <Route component={NavBar} path="/" />
          <Route component={About} path="/" exact />
          <Route component={About} path="/about" exact />
          <Route component={Films} path="/films/:film" />
          <Route component={Other} path="/other/:other" />
        </Router>
      </Provider>
    </div>
  );
}

export default App;
