import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Context } from "../../context/GlobalContextProvider";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReactComponent as Play } from "../../assets/PlayIcon.svg";
import "../scss/Films.scss";

export default function Contact(props) {
  const { state, fetchOther } = useContext(Context);
  const { other } = state;
  const [otherContent, setOtherContent] = useState(null);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);

  const slug = props.match.params.other;

  useEffect(() => {
    setPlay(false);
    !other.length && fetchOther();
    other.length &&
      slug &&
      setOtherContent(other.find((f) => f.fields.slug == slug));
  }, [other, slug]);

  function playHandler() {
    otherContent && otherContent.fields.url &&
    setPlay(true);
    setLoading(true);
  }

  return (
    <div className="film-wrapper">
      <div className="film-container">
        {play ? (
          <>
            <div
              className={
                loading ? "video-wrapper invisible" : "video-wrapper visible"
              }
            >
              <ReactPlayer
                playing={true}
                onReady={() => setLoading(false)}
                url={otherContent && otherContent.fields.url}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </>
        ) : (
          <div className="preview-image" onClick={() => playHandler()}>
            {otherContent && otherContent.fields.url && (
              <div className="play">
                <Play />
              </div>
            )}
            <img
              src={
                otherContent &&
                `${otherContent.fields.stillImage.fields.file.url}?w=750`
              }
              alt=""
            />
          </div>
        )}
      </div>
      <div className="film-info">
        <div className="film-title">
          {otherContent && otherContent.fields.name}
        </div>
        <div className="film-date-year">
          {otherContent && otherContent.fields.year} -{" "}
          {otherContent && otherContent.fields.duration}
        </div>
        <div className="film-description">
          {otherContent &&
            documentToReactComponents(otherContent.fields.description)}
        </div>
      </div>
    </div>
  );
}
