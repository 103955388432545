import { createClient } from "contentful";

import createDataContext from "./createDataContext";

const initialState = {
  films: [],
  other: [],
  about: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_FILMS": {
      return {
        ...state,
        films: action.payload,
      };
    }
    case "SET_ABOUT": {
      return {
        ...state,
        about: action.payload,
      };
    }
    case "SET_OTHER": {
      return {
        ...state,
        other: action.payload,
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const fetchFilms = (dispatch) => {
  console.log("fetching");
  return () => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({
        content_type: "films",
      })
      .then((response) =>
        dispatch({
          type: "SET_FILMS",
          payload: response.items,
        })
      )
      .catch(console.error);
  };
};

const fetchAbout = (dispatch) => {
  console.log("fetch about");
  return () => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({
        content_type: "about",
      })
      .then((response) =>
        dispatch({
          type: "SET_ABOUT",
          payload: response.items,
        })
      )
      .catch(console.error);
  };
};

const fetchOther = (dispatch) => {
  console.log("fetch other");
  return () => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({
        content_type: "other",
      })
      .then((response) =>
        dispatch({
          type: "SET_OTHER",
          payload: response.items,
        })
      )
      .catch(console.error);
  };
};

const fetchNothing = () => {
  console.log("fetch nothing");
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    fetchFilms,
    fetchOther,
    fetchAbout,
    fetchNothing,
  },
  initialState
);
