import React, { useContext, useEffect } from "react";

import Collapsible from "react-collapsible";
import { Context } from "../../context/GlobalContextProvider";
import { ReactComponent as Logo } from "../../assets/alexbackhouse_logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import "../scss/NavBar.scss";

function NavBarLarge() {
  const { state, fetchFilms, fetchOther } = useContext(Context);
  const { films, other } = state;
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    fetchFilms();
    fetchOther();
  }, []);

  return (
    <div className="large-nav-wrapper">
      <div className="logo-container">
        <Logo />
      </div>
      <div className="large-nav-links">
        <Collapsible
          transitionTime={150}
          trigger="FILMS"
          className="large-nav-collapsible"
        >
          {films.length &&
            films.map((i) => (
              <NavLink
                to={`/films/${i.fields.slug}`}
                className="not-selected"
                activeClassName="selected"
                isActive={() => {
                  if (location.pathname == `/films/${i.fields.slug}`) {
                    console.log(true);
                    return true;
                  } else {
                    return false;
                  }
                }}
              >
                {i.fields.name}
              </NavLink>
            ))}
        </Collapsible>
        <Collapsible
          transitionTime={150}
          trigger="OTHER"
          className="large-nav-collapsible"
        >
          {other.length &&
            other.map((i) => (
              <NavLink
                to={`/other/${i.fields.slug}`}
                className="not-selected"
                activeClassName="selected"
                isActive={() => {
                  if (location.pathname == `/other/${i.fields.slug}`) {
                    console.log(true);
                    return true;
                  } else {
                    return false;
                  }
                }}
              >
                {i.fields.name}
              </NavLink>
            ))}
        </Collapsible>
        <div className="large-nav-item">
          <NavLink
            to="/about"
            activeClassName="selected"
            className="not-selected"
          >
            ABOUT
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NavBarLarge;
