import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Context } from "../../context/GlobalContextProvider";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReactComponent as Play } from "../../assets/PlayIcon.svg";
import "../scss/Films.scss";

export default function Films(props) {
  const { state, fetchFilms } = useContext(Context);
  const { films } = state;
  const [film, setFilm] = useState(null);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);

  const slug = props.match.params.film;

  console.log(film);

  useEffect(() => {
    setPlay(false);
    !films && fetchFilms();
    films && slug && setFilm(films.find((f) => f.fields.slug == slug));
  }, [films, slug]);

  function playHandler() {
    film && film.fields.url &&
    setPlay(true);
    setLoading(true);
  }
  return (
    <div className="film-wrapper">
      <div className="film-container">
        {play ? (
          <>
            <div
              className={
                loading ? "video-wrapper invisible" : "video-wrapper visible"
              }
            >
              <ReactPlayer
                playing={true}
                onReady={() => setLoading(false)}
                url={film && film.fields.url}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </>
        ) : (
          <div className="preview-image" onClick={() => playHandler()}>
            {film && film.fields.url && (
              <div className="play">
                <Play />
              </div>
            )}
            <img
              src={film && `${film.fields.stillImage.fields.file.url}?w=750`}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="film-info">
        <div className="film-title">{film && film.fields.name}</div>
        <div className="film-date-year">
          {film && film.fields.year} - {film && film.fields.duration}
        </div>
        <div className="film-description">
          {film && documentToReactComponents(film.fields.description)}
        </div>
      </div>
    </div>
  );
}
