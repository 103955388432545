import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Collapsible from "react-collapsible";
import { Context } from "../../context/GlobalContextProvider";
import { ReactComponent as Logo } from "../../assets/alexbackhouse_logo.svg";
import "../scss/NavBar.scss";

function NavBarAdmin() {
  const [checked, setChecked] = useState(false);

  const checkHandler = () => {
    setChecked(!checked);
  };

  const { state, fetchFilms, fetchOther } = useContext(Context);
  const { films, other } = state;
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    fetchFilms();
    fetchOther();
  }, []);

  return (
    <div className="nav-small">
      <div
        className={checked ? "screen-mask" : "screen-mask-none"}
        onClick={checkHandler}
      ></div>
      <div className="logo-container-small">
        <Logo />
      </div>
      <div className="nav-container">
        <div className="checkbox-wrapper">
          <input type="checkbox" checked={checked} onChange={checkHandler} />
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div id={checked ? "menu-checked" : "menu-unchecked"}>
          <div className="small-nav-links">
            <Collapsible
              transitionTime={150}
              trigger="FILMS"
              className="small-nav-collapsible"
              openedClassName="small-nav-collapsible"
              triggerClassName="small-nav-collapsible-inner"
              triggerOpenedClassName="small-nav-collapsible-inner"
              contentInnerClassName="small-nav-collapsible-inner-content"
            >
              {films.length &&
                films.map((i) => (
                  <NavLink
                    to={`/films/${i.fields.slug}`}
                    onClick={checkHandler}
                    className="not-selected-small"
                    activeClassName="selected-small"
                    isActive={() => {
                      if (location.pathname == `/films/${i.fields.slug}`) {
                        console.log(true);
                        return true;
                      } else {
                        return false;
                      }
                    }}
                  >
                    {i.fields.name}
                  </NavLink>
                ))}
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="OTHER"
              className="small-nav-collapsible"
              openedClassName="small-nav-collapsible"
              triggerClassName="small-nav-collapsible-inner"
              triggerOpenedClassName="small-nav-collapsible-inner"
              contentInnerClassName="small-nav-collapsible-inner-content"
            >
              {other.length &&
                other.map((i) => (
                  <NavLink
                    to={`/other/${i.fields.slug}`}
                    onClick={checkHandler}
                    className="not-selected-small"
                    activeClassName="selected-small"
                    isActive={() => {
                      if (location.pathname == `/other/${i.fields.slug}`) {
                        console.log(true);
                        return true;
                      } else {
                        return false;
                      }
                    }}
                  >
                    {i.fields.name}
                  </NavLink>
                ))}
            </Collapsible>
            <div className="small-nav-item">
              <NavLink
                to="/about"
                onClick={checkHandler}
                activeClassName="selected-small"
                className="not-selected-small"
              >
                ABOUT
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBarAdmin;
