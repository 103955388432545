import React, { useContext, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Context } from "../../context/GlobalContextProvider";

import { ReactComponent as Insta } from "../../assets/insta_logo.svg";
import { ReactComponent as Imdb } from "../../assets/imdb_logo.svg";
import { ReactComponent as Vimeo } from "../../assets/vimeo_logo.svg";

import "../scss/About.scss";

export default function Home() {
  const { state, fetchAbout } = useContext(Context);
  const { about } = state;

  console.log(about);
  useEffect(() => {
    !about && fetchAbout();
  }, [fetchAbout]);

  return (
    <div className="home-page-wrapper">
      <div className="film-description">
        {about && documentToReactComponents(about[0].fields.about)}
      </div>
      <div className="about-logos">
        <a href="https://www.instagram.com/alex.backhouse/?hl=en" className="link">
        <Insta />
        </a>
        <a href="https://www.imdb.com/name/nm4977496/?ref_=nv_sr_srsg_0" className="link">
        <Imdb />
        </a>
        <a href="https://vimeo.com/user14283765" className="link">
        <Vimeo />
        </a>
      </div>
    </div>
  );
}
